
import WineDetailItem from '@/components/WineDetailItem.vue';
import BarItem from '@/shared/components/BarItem.vue';
import { Wine } from '@/shared/models/Wine';

export default {
    props: {
        items: {
            type: Array<Wine>(),
            required: true,
        },
    },
    components: {
        WineDetailItem,
        BarItem,
    },
    setup() {
        return {};
    },
};
