import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-row flex-wrap justify-content-evenly align-items-start" }
const _hoisted_2 = { class: "text-break" }
const _hoisted_3 = {
  key: 0,
  class: "pt-1 d-block text-start d-lg-none"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "info-block text-start" }
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_8 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_9 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_10 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_11 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_12 = { class: "d-flex flex-row justify-content-start" }
const _hoisted_13 = {
  key: 0,
  class: "img-small",
  src: "/images/vegan.png"
}
const _hoisted_14 = {
  key: 1,
  class: "img-small",
  src: "/images/bio.png"
}
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "d-flex flex-column" }
const _hoisted_17 = { class: "d-flex flex-row mb-2" }
const _hoisted_18 = { class: "border-bottom d-flex flex-row justify-content-between py-3" }
const _hoisted_19 = { class: "d-block w-50" }
const _hoisted_20 = ["href", "download"]
const _hoisted_21 = { class: "d-block w-50" }
const _hoisted_22 = ["href", "download"]
const _hoisted_23 = {
  key: 0,
  class: "pt-5 d-none d-lg-block"
}
const _hoisted_24 = {
  key: 0,
  id: "options"
}
const _hoisted_25 = { id: "more-wines" }
const _hoisted_26 = { class: "swiper-slide clickable" }
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "mb-1" }
const _hoisted_29 = { class: "info-card text-center" }
const _hoisted_30 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_LogoDivider = _resolveComponent("LogoDivider")!
  const _component_WineDetailItems = _resolveComponent("WineDetailItems")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return ($setup.wine && Object.keys($setup.wine).length !== 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["wine-detail-container container pb-5", $setup.wine?.color === 'rood' ? 'bg-wine-red' : $setup.wine.color === 'wit' ? 'bg-wine-white' : 'bg-wine-rose'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.wine.is_favorited ? $setup.HeartIcon : $setup.HeartOutlineIcon), {
              class: "d-block d-lg-none clickable icon",
              onClick: $setup.favorite
            }, null, 8, ["onClick"])),
            _createTextVNode(" " + _toDisplayString($setup.wine.name) + " ", 1),
            ($setup.items)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Button, {
                    text: $setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.scrollIntoView('options')))
                  }, null, 8, ["text"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("img", {
            class: "wine-bottle",
            src: $setup.wine.image_large,
            alt: "wine"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: "#",
              class: "d-none d-lg-block favorite border-bottom",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.favorite && $setup.favorite(...args)))
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($setup.wine.is_favorited ? $setup.HeartIcon : $setup.HeartOutlineIcon), { class: "d-none d-lg-inline clickable icon" })),
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "Favoriet", -1))
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "d-block border-bottom d-lg-none" }, null, -1)),
            _createVNode(_component_Accordion, {
              label: "Over deze wijn",
              show: ""
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[4] || (_cache[4] = _createElementVNode("p", null, "Land", -1)),
                    _createElementVNode("p", null, _toDisplayString($setup.wine.country?.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[5] || (_cache[5] = _createElementVNode("p", null, "Streek", -1)),
                    _createElementVNode("p", null, _toDisplayString($setup.wine.region?.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[6] || (_cache[6] = _createElementVNode("p", null, "Wijnhuis", -1)),
                    _createElementVNode("p", null, _toDisplayString($setup.wine.estate?.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[7] || (_cache[7] = _createElementVNode("p", null, "Type wijn", -1)),
                    _createElementVNode("p", null, _toDisplayString($setup.wine.color), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[8] || (_cache[8] = _createElementVNode("p", null, "Druivenras", -1)),
                    _createElementVNode("p", null, _toDisplayString($setup.wine.grapes?.map((grape) => grape.name).join(', ')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[9] || (_cache[9] = _createElementVNode("p", null, null, -1)),
                    ($setup.wine.vegan)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                      : _createCommentVNode("", true),
                    ($setup.wine.bio)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Accordion, { label: "Overige informatie" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id,
                      class: "d-block"
                    }, [
                      _createElementVNode("div", _hoisted_16, [
                        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "m-0" }, " Artikelnummer ", -1)),
                        _createElementVNode("p", null, _toDisplayString(item.id), 1)
                      ]),
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex flex-row" }, [
                        _createElementVNode("p", null, "Inhoud"),
                        _createElementVNode("p", null, "Eenheid"),
                        _createElementVNode("p", null, "Jaar"),
                        _createElementVNode("p", null, "Alcohol")
                      ], -1)),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("p", null, _toDisplayString(item.volume) + "L", 1),
                        _createElementVNode("p", null, _toDisplayString(item.units_per_parcel), 1),
                        _createElementVNode("p", null, _toDisplayString(item.year_of_harvest !== '0' ? item.year_of_harvest : 'N.M.'), 1),
                        _createElementVNode("p", null, _toDisplayString(item?.alcohol_percentage?.replace('.', ',')) + "%", 1)
                      ])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("a", {
                  class: "btn-clean",
                  href: $setup.wine.brochure,
                  target: "_blank",
                  download: $setup.wine.name
                }, " Download wijnomschrijving ", 8, _hoisted_20)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("a", {
                  class: "btn-clean",
                  href: $setup.wine.image_large,
                  target: "_blank",
                  download: $setup.wine.name
                }, " Download flesafbeelding ", 8, _hoisted_22)
              ])
            ]),
            ($setup.items)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(_component_Button, {
                    text: $setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie'),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.scrollIntoView('options')))
                  }, null, 8, ["text"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_LogoDivider),
        ($setup.items)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("h2", null, _toDisplayString($setup.items.length + ($setup.items.length > 1 ? ' Bestelopties' : ' Besteloptie')), 1),
              _createVNode(_component_WineDetailItems, { items: $setup.items }, null, 8, ["items"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_LogoDivider),
        _createElementVNode("div", _hoisted_25, [
          _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Ook van dit wijnhuis", -1)),
          _createVNode(_component_swiper, {
            "slides-per-view": $setup.width < 992 ? 3 : $setup.related.length < 5 ? $setup.related.length : 5,
            "space-between": 20,
            scrollbar: { draggable: true }
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.related, (relatedItem) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: relatedItem.id,
                  onClick: ($event: any) => ($setup.openWine(relatedItem))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("img", {
                        class: "mb-3",
                        src: relatedItem.image_small
                      }, null, 8, _hoisted_27),
                      _createElementVNode("h3", _hoisted_28, _toDisplayString(relatedItem.name), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "readmore" }, " Lees meer ", -1))
                    ])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["slides-per-view"]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("p", _hoisted_30, " Benieuwd naar de andere wijnen van " + _toDisplayString($setup.wine.estate?.name) + "? Neem dan hier een kijkje. ", 1),
            _createVNode(_component_Button, {
              text: "Bekijk aanbod",
              design: "primary",
              onClick: $setup.navigateToEstate
            }, null, 8, ["onClick"])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}