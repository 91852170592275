import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WineDetailItem = _resolveComponent("WineDetailItem")!
  const _component_BarItem = _resolveComponent("BarItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BarItem, {
      class: "d-none d-lg-block",
      show: "",
      design: "no-margin"
    }, {
      content: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "option-title" }, " Artikelnummer ", -1),
        _createElementVNode("div", { class: "option-title" }, " Jaar ", -1),
        _createElementVNode("div", { class: "option-title" }, " Inhoud ", -1),
        _createElementVNode("div", { class: "option-title" }, " Doos Inhoud ", -1),
        _createElementVNode("div", { class: "option-title" }, " Prijs ", -1),
        _createElementVNode("div", { class: "option-title" }, " Voorraad ", -1),
        _createElementVNode("div", { class: "option-title" }, " Aantal dozen ", -1)
      ])),
      items: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (option, index) => {
          return (_openBlock(), _createBlock(_component_WineDetailItem, {
            key: option.id,
            item: option,
            design: (index === $props.items.length - 1) ? 'no-margin extra-padding' : 'no-margin',
            divider: index !== $props.items.length - 1 ? 'bottom' : ''
          }, null, 8, ["item", "design", "divider"]))
        }), 128))
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (option) => {
      return (_openBlock(), _createBlock(_component_WineDetailItem, {
        key: option.id,
        class: "d-block d-lg-none",
        item: option
      }, null, 8, ["item"]))
    }), 128))
  ]))
}