
import 'swiper/swiper.scss';
import { plainToClass } from 'class-transformer';
import SwiperCore from 'swiper';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import HeartOutlineIcon from '@/assets/icons/heart-outline.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import WineDetailItems from '@/components/WineDetailItems.vue';
import Accordion from '@/shared/components/Accordion.vue';
import Button from '@/shared/components/Button.vue';
import LogoDivider from '@/shared/components/LogoDivider.vue';
import { Wine } from '@/shared/models/Wine';
import 'swiper/components/scrollbar/scrollbar.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Scrollbar } = require('swiper');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Swiper, SwiperSlide } = require('swiper/vue');

SwiperCore.use([Scrollbar]);
export default {
    components: {
        WineDetailItems,
        Button,
        Accordion,
        Swiper,
        SwiperSlide,
        LogoDivider,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const wine = ref(new Wine());
        const items = ref(Array<Wine>());
        const related = ref(Array<Wine>());
        const loading = ref(false);

        function getWine(id?: string) {
            loading.value = true;
            const wineId = id || route.params.id;

            store.dispatch('WINE_GET', wineId).then((res: any) => {
                wine.value = plainToClass(Wine, res.data.wine);
                items.value = plainToClass(Wine, res.data.items as Wine[]);
                related.value = plainToClass(Wine, res.data.related as Wine[]);

                const dataLayer = window.dataLayer || [];
                const structuredItem = {
                    item_id: wine.value.no,
                    item_name: wine.value.name,
                    index: 0,
                    item_brand: wine.value.color,
                    item_category: wine.value.country.name,
                    item_category2: wine.value.region.name,
                    item_category3: wine.value.estate.name,
                    item_category4: `${wine.value.alcohol_percentage}%`,
                    item_category5: wine.value.grapes.map((grape) => grape.name).join(', '),
                    price: wine.value.price,
                    quantity: wine.value.quantity,
                };
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: 'view_item',
                    ecommerce: {
                        currency: 'EUR',
                        value: wine.value.price,
                        items: structuredItem,
                    },
                });

                loading.value = false;
            });
        }

        const width = ref(window.innerWidth);

        function handleResize(event: any) {
            width.value = event.target.innerWidth;
        }

        onMounted(() => {
            window.addEventListener('resize', handleResize);
            getWine();
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        function favorite() {
            wine.value.is_favorited = !wine.value.is_favorited;
            store.dispatch('WINE_FAVORITE', wine.value).then((res) => {
                wine.value = plainToClass(Wine, res.data.data);
            });
        }

        const router = useRouter();

        function openWine(relatedWine: Wine) {
            const id = String(relatedWine.id);
            router.push({ name: 'AssortimentDetail', params: { id } });
            getWine(id);
            window.scrollTo(0, 0);
        }

        function navigateToEstate() {
            if (wine.value.estate) {
                router.push({ name: 'Assortiment', query: { wijnhuis: wine.value.estate.name } });
            }
        }

        function scrollIntoView(item: any) {
            const element = document.querySelector(`#${item}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return {
            wine,
            items,
            related,
            favorite,
            openWine,
            navigateToEstate,
            width,
            scrollIntoView,
            HeartIcon,
            HeartOutlineIcon,
            loading,
        };
    },
};
